<template>
  <div class="examinerManagement">
    <el-radio-group
      @change="radioChange"
      style="margin-bottom: 10px"
      v-model="radio1"
    >
      <el-radio-button v-if="isMoudleManager" label="发布"></el-radio-button>
      <el-radio-button label="接收"></el-radio-button>
    </el-radio-group>

    <div class="flex-box">
      <div class="left">
        <el-button
          v-if="radio1 == '发布'"
          type="success"
          @click="PublishInformation"
          >发布</el-button
        >
      </div>
      <div class="flex-box">
        <el-input
          clearable
          @clear="loadPublishInformation(1)"
          placeholder="请输入标题关键字"
          class="picker"
          v-model="query.name"
        ></el-input>
        <!-- 学年？ -->

        <el-button
          type="primary"
          class="picker"
          @click="loadPublishInformation(1)"
          >查 询</el-button
        >
      </div>
    </div>
    <el-table :data="PublishInformationTable">
      <el-table-column
        label="标题"
        prop="title"
        align="center"
      ></el-table-column>
      <el-table-column
        label="发布人"
        prop="opername"
        align="center"
      ></el-table-column>
      <el-table-column v-if="this.radio1 == '接收'" label="状态" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status == '未读' ? 'danger' : 'success'">{{
            scope.row.status
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="时间"
        prop="opertime"
        align="center"
      ></el-table-column>

      <el-table-column label="操作" width="270px" prop="course" align="center">
        <template slot-scope="scope">
          <div v-if="isMoudleManager && radio1 != '接收'">
            <el-button
              size="small"
              type="primary"
              @click="seePublishInformation(scope.row)"
              >编辑
            </el-button>
            <el-button
              size="small"
              type="danger"
              @click="deletePublishInformation(scope.row.id)"
              >删除
            </el-button>
            <el-button
              size="small"
              type="info"
              @click="seeReadConditionClick(scope.row.id)"
              >查看阅读情况
            </el-button>
          </div>
          <div v-if="!isMoudleManager || radio1 == '接收'">
            <el-button
              size="small"
              type="primary"
              @click="seePublishInformation(scope.row)"
              >查看
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev,pager,next"
      background
      :page-count="page.pageCount"
      :page-size="page.currentPage"
      @current-change="loadPublishInformation"
    ></el-pagination>

    <!-- <el-dialog
      title="查看阅读状况"
      :visible.sync="readConditionDialogVisible"
      width="600px"
    >
      <div class="center">
        <el-form
          :model="addPublishInformation"
          ref="addPublishInformationFormRef"
          label-width="100px"
          :rules="addPublishInformationFormRules"
        >
        </el-form>
      </div>
      <span v-if="!disabledStatus" slot="footer" class="dialog-footer">
        <el-button @click="PublishInformationFormDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="publishInformationConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->

    <el-dialog
      :title="title"
      :visible.sync="PublishInformationFormDialogVisible"
      width="600px"
    >
      <div class="center">
        <el-form
          :model="addPublishInformation"
          ref="addPublishInformationFormRef"
          label-width="100px"
          :rules="addPublishInformationFormRules"
        >
          <el-form-item label="标题：" prop="title">
            <el-input
              :disabled="disabledStatus"
              v-model="addPublishInformation.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="类型：" prop="type">
            <el-select
              :disabled="disabledStatus"
              clearable
              class=""
              v-model="addPublishInformation.type"
              placeholder="请选择类型"
            >
              <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.label"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="附件：">
            <el-upload
              class="upload-demo"
              action="/api/base/uploadByOss.do"
              :headers="{Authorization:token}"
              :limit="1"
              :on-remove="onRemoveFile"
              ref="upload"
              :on-success="enclosureUploadSuccess"
              :data="{path:'InformationPublish',name:''}"
              :show-file-list="true">
              <el-button size="small" type="primary">点击上传</el-button>
              <div v-if="addPublishInformation.url" slot="tip" class="el-upload__tip">已上传附件，可下载附件，也可重新上传</div>
            </el-upload>
            <el-button type="primary"
                     class="load-file"
                     @click="loadFile"
                     size="small"
                     v-if="addPublishInformation.url">下载附件</el-button>
          </el-form-item>

        </el-form>
      </div>
      <span v-if="!disabledStatus" slot="footer" class="dialog-footer">
        <el-button @click="PublishInformationFormDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="publishInformationConfirm"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "InformationPublish",
  data() {
    return {
      token: sessionStorage.getItem("token"),
      page: { pageCount: 1, currentPage: 0 },
      PublishInformationTable: [],
      PublishInformationFormDialogVisible: false,
      addPublishInformation: {
        id: "",
        title: "",
        type: "",
        url: "",
      },
      addPublishInformationFormRules: {
        title: [{ required: true }],
        type: [{ required: true }],
        url: [{ required: true }],
      },
      //   类型
      typeList: [
        { id: 1, label: "全校" },
        { id: 2, label: "党内" },
      ],
      query: {
        start_time: "",
        end_time: "",
        name: "",
      },
      disabledStatus: false,
      // 角色类型
      role_type: JSON.parse(sessionStorage.getItem("userInfo")).role_type,
      // 阅读状况对话框显示与隐藏
      readConditionDialogVisible: false,
      // 查询
      query: {
        name: "",
      },
      radio1: "发布",
      moudleList: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      isMoudleManager: false,
      title: "",
    };
  },
  created() {
    this.radio1 = "接收";
    // 判断是否是模块管理员
    for (let i = 0; i < this.moudleList.length; i++) {
      if (this.moudleList[i].id === 1201) {
        // 是模块管理员
        this.isMoudleManager = true;
        this.radio1 = "发布";
      }
    }
    this.loadPublishInformation(1);
  },
  methods: {
    enclosureUploadSuccess(res, file) {
      this.addPublishInformation.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.addPublishInformation.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.addPublishInformation.url = "";
    },
    radioChange(value) {
      this.loadPublishInformation(1);
    },
    // 获取初始页面数据
    loadPublishInformation(page) {
      const that = this;
      let data = {
        indexNo: page,
        start_time: this.query.start_time,
        end_time: this.query.end_time,
        name: this.query.name,
      };
      let url;
      if (!this.isMoudleManager || this.radio1 == "接收") {
        // 教师
        url = "/base/queryInformationByTea.do";
      } else if (this.isMoudleManager) {
        // 管理员
        url = "/base/queryInformation.do";
      }

      this.$post(url, data)
        .then((res) => {
          console.log(res);
          that.page.currentPage = res.data.indexNO;
          that.page.pageCount = res.data.pageCount;

          that.PublishInformationTable = res.data.rows;
          console.log(that.PublishInformationTable);
        })
        .catch((err) => {
          that.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    // 添加信息
    PublishInformation() {
      this.disabledStatus = false;
      this.title = "发布信息";
      this.PublishInformationFormDialogVisible = true;
      this.$nextTick(() => {
        //   console.log(1)
        this.$refs.addPublishInformationFormRef.resetFields();
        this.$refs.upload.clearFiles();
        this.addPublishInformation.id = "";
        this.addPublishInformation.url = ''
      });
    },
    // 编辑和查看
    seePublishInformation(row) {
      if (this.isMoudleManager) {
        this.title = this.radio1 == '接收' ? '查看信息' : '编辑信息'
        this.disabledStatus = this.radio1 == '接收' ? true : false
      } else if (!this.isMoudleManager) {
        this.title = "查看信息";
        this.disabledStatus = true;
      }

      // 显示对话框
      this.PublishInformationFormDialogVisible = true;
      // 把数据赋值给编辑表单
      this.$nextTick(() => {
        this.addPublishInformation.id = row.id;
        this.addPublishInformation.title = row.title;
        this.addPublishInformation.type = row.type;
        this.addPublishInformation.url = row.url;
        this.$refs.upload.clearFiles();
      });
      // 更新状态
      this.$post(`base/updateInformationStatus.do?id=${row.id}`).then((res) => {
        console.log(res);
        this.loadPublishInformation(this.page.currentPage);
      });
    },
    // 表单提交
    publishInformationConfirm() {
      this.$refs.addPublishInformationFormRef.validate((validate) => {
        if (validate) {
          // 编辑信息表单提交
          if (this.title == "编辑信息") {
            this.$post(
              "/base/updateInformation.do",
              this.addPublishInformation
            ).then((res) => {
              this.$message.success("编辑成功");
              this.PublishInformationFormDialogVisible = false;
              // 更新当前页数据列表
              this.loadPublishInformation(this.page.currentPage);
            });
            return;
          }
          // 添加信息表单提交
          const data = {
            title: this.addPublishInformation.title,
            type: this.addPublishInformation.type,
            url: this.addPublishInformation.url,
          };
          console.log(data);
          this.$post("/base/insertInformation.do", data)
            .then((res) => {
              this.$message.success("添加成功");
              this.PublishInformationFormDialogVisible = false;
              this.loadPublishInformation(1);
              console.log(res);
            })
            .catch((err) => {
            });
        }
      });
    },
    // 删除
    deletePublishInformation(id) {
      const that = this;
      this.$confirm("是否删除该条记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$post(`/base/deleteInformation.do?id=${id}`)
            .then((res) => {
              that.$message({
                type: "success",
                message: "删除成功!",
              });
              that.loadPublishInformation(this.page.currentPage);
            })
            .catch((err) => {
              that.$message.error(err.message);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 点击查看阅读状况
    seeReadConditionClick(id) {
      this.$router.push({
        name: "TemplateReadCondition",
        query: {
          readid: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}

.load-file{
  margin-top: 20px;
}
</style>
